import React, { Suspense } from "react";

const quickCard = React.lazy(() => import("./BankingPlusQnA/CardQuickReply"));
const CarouselCards = React.lazy(() => import("./BankingPlusQnA/CarouselCards"));
const LocationCarouselCards = React.lazy(
  () => import("./BankingPlusQnA/LocationCarouselCards")
);
const cardRadioBullet = React.lazy(
  () => import("./BankingPlusQnA/CardRadioBullet")
);
const confirmationCard = React.lazy(
  () => import("./BankingPlusQnA/CardConfirmation")
);
// const Buttons = React.lazy(() => import("./BankingPlusQnA/Buttons"));
const bankingButtons = React.lazy(() => import("./BankingPlusQnA/Buttons"));
const CardRadio = React.lazy(() => import("./BankingPlusQnA/CardRadio"));
const cardList = React.lazy(() => import("./BankingPlusQnA/CardList"));

const multiSelect = React.lazy(() => import("./InsurancePlus/MultiSelect"));
const autoPolicy = React.lazy(() => import("./InsurancePlus/AutoPolicy"));

const healthPlan = React.lazy(() => import("./HealthPlus/MedicalPlanUsage"));
const delayedReply = React.lazy(() => import("./FaqAssistant/DelayedReply"));

const openai = React.lazy(() => import("./OpenAI/ResponseCard"));
const buttons = React.lazy(() => import("./Venmo/PromptButtons"));
const greetings = React.lazy(() => import("./common/GreetingsCard"));

// const driverVehicle = React.lazy(() => import("./FNOL/DriverVehicle"));
// const otherPartyInformation = React.lazy(
//   () => import("./FNOL/OtherPartyInformation")
// );
const otherPartyDriverLicenseInsurance = React.lazy(
  () => import("./FNOL/OtherPartyDriverLicense_Insurance")
);
// const otherPartyDriverContact = React.lazy(
//   () => import("./FNOL/OtherPartyDriverContact")
// );
const dateTime = React.lazy(() => import("./FNOL/DateTime"));
const damageParts = React.lazy(() => import("./FNOL/DamageParts"));
const reviewAndConfirm = React.lazy(() => import("./FNOL/ReviewAndConfirm"));
const searchRepairShop = React.lazy(() => import("./FNOL/SearchRepairShop"));
const searchResults = React.lazy(() => import("./FNOL/SearchResults"));
const cancel = React.lazy(() => import("./FNOL/Cancel"));
const confirmationForm = React.lazy(
  () => import("./common/ConfirmationForm")
);
const processProgress = React.lazy(
  () => import("./common/ProcessProgress")
);
const locationOfIncident = React.lazy(
  () => import("./FNOL/LocationOfIncident")
);
// const injuryDamageDetails = React.lazy(
//   () => import("./FNOL/InjuryDamageDetails")
// );
const incidentDetails = React.lazy(() => import("./FNOL/IncidentDetails"));
// const claimSubmissionStatus = React.lazy(
//   () => import("./FNOL/ClaimSubmissionStatus")
// );
const vehicleInformation = React.lazy(
  () => import("./FNOL/VehicleInformation")
);
const location = React.lazy(() => import("./common/LocationPicker"));
const confirmationPopUp = React.lazy(() => import("./common/ConfirmationPopUp"));

const IncidentTypeConfirmation = React.lazy(
  () => import("./InsurancePlus/IncidentTypeConfirmation")
);
// const fnolForm = React.lazy(() => import("./FNOL/FNOLForm"));
// const roadsideAssistanceContactInfo = React.lazy(
//   () => import("./FNOL/RoadsideAssistanceContactInfo")
// );
// const assistanceType = React.lazy(() => import("./FNOL/AssistanceType"));
// const towDestination = React.lazy(() => import("./FNOL/TowDestination"));
const reviewAndConfirmRoadside = React.lazy(
  () => import("./FNOL/ReviewAndConfirmRoadside")
);
const assistanceRequested = React.lazy(
  () => import("./FNOL/AssistanceRequested")
);
const form = React.lazy(() => import("./form/form"));
//const visual_picker = React.lazy(() => import("./form/VisualPicker"));

const ocrForm = React.lazy(() => import("./form/OcrForm"));
const searchRepairShopModal = React.lazy(() => import("./FNOL/SearchRepairShopModal"));

const heroTile = React.lazy(() => import("./common/HeroTile"));
const tileCarouselCard = React.lazy(() =>
  import("./common/TileCarouselCard")
);
const transactionDetailSelectable = React.lazy(
  () => import("./BankingPlus/TransactionDetailSelectableTile")
);

const selectableTileCarousel = React.lazy(
  () => import("./common/SelectableTileCarousel")
);

const registeredComponents = {
  quickCard,
  CarouselCards,
  LocationCarouselCards,
  cardRadioBullet,
  confirmationCard,
  // Buttons,
  bankingButtons,
  buttons,
  CardRadio,
  cardList,

  multiSelect,
  autoPolicy,

  healthPlan,
  delayedReply,

  openai,
  // button,
  greetings,

  // driverVehicle,
  // otherPartyInformation,
  otherPartyDriverLicenseInsurance,
  // otherPartyDriverContact,
  dateTime,
  damageParts,
  reviewAndConfirm,
  searchRepairShop,
  searchResults,
  cancel,
  confirmationForm,
  processProgress,
  locationOfIncident,
  // injuryDamageDetails,
  incidentDetails,
  // claimSubmissionStatus,
  vehicleInformation,
  location,
  confirmationPopUp,
  IncidentTypeConfirmation,
  // fnolForm,
  // roadsideAssistanceContactInfo,
  // assistanceType,
  // towDestination,
  reviewAndConfirmRoadside,
  assistanceRequested,
  form,
  //visual_picker,
  ocrForm,
  searchRepairShopModal,
  heroTile,
  tileCarouselCard,
  selectableTileCarousel,
  transactionDetailSelectable,
};

export default function CustomComponent(props) {
  const CustomComponent = registeredComponents[props.component];
  const content = props.data;
  const scrollToBottom = props.scrollToBottom;

  return (
    <Suspense>
      <CustomComponent data={content} scrollToBottom={scrollToBottom} />
    </Suspense>
  );
}

import { useSignal } from '@preact/signals-react';
import React from 'react';
import {
  addToConversation,
  disableInput,
  isBotTyping,
} from './store/chatStore';

export default function ChatBotInput() {
  const userInput = useSignal('');
  const handleUserInput = async (
    e:
      | React.KeyboardEvent<HTMLInputElement>
      | React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    if (
      (('key' in e && e.key === 'Enter') ||
        ('type' in e && e.type === 'click')) &&
      userInput.value !== ''
    ) {
      addToConversation({
        bot: false,
        element: userInput.value,
      });
      userInput.value = '';
    }
  };
  return (
    <div className="ts-input-wrapper">
      <input
        className="user-input"
        value={userInput.value}
        onInput={(event) => (userInput.value = event.currentTarget.value)}
        type="text"
        name="user-input"
        id="user-input"
        onKeyUp={handleUserInput}
        disabled={isBotTyping.value || disableInput.value}
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="19"
        viewBox="0 0 20 19"
        fill="none"
        onClick={handleUserInput}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.29886 8.22139L0.97615 0.518526C0.906514 0.387545 0.923922 0.231615 1.01677 0.119347C1.10962 0.00707817 1.24889 -0.030344 1.38236 0.0257893L19.0736 9.17567C19.2012 9.2318 19.2767 9.35655 19.2767 9.5C19.2767 9.64346 19.1954 9.7682 19.0736 9.82433L1.37656 18.9742C1.24889 19.0303 1.10382 18.9929 1.01097 18.8807C0.91812 18.7684 1.37656 18.9742 0.970347 18.4815L1.29886 10.7786L11.4136 9.62474C11.489 9.61851 11.547 9.56237 11.547 9.5C11.547 9.43763 11.489 9.3815 11.4136 9.37526L1.29886 8.22139Z"
          fill="#999999"
        />
      </svg>
    </div>
  );
}

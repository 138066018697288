import { signal } from "@preact/signals-react";
import { FlowStep, OutComes, SurfaceMessage } from "../types";
import React from "react";

type ChatBody = {
  bot: boolean;
  element: string | React.ReactNode;
  jsonObject?: { [key: string]: unknown };
  isOpenAI?: boolean;
};

class MessageBroker extends EventTarget {
  constructor() {
    super();
  }

  sendMessage(message: SurfaceMessage) {
    this.dispatchEvent(new CustomEvent("message", { detail: message }));
  }
}

export const conversationState = signal<ChatBody[] | []>([]);
export const isBotTyping = signal<boolean>(false);
export const appId = signal("");
export const flowId = signal("");
export const stepId = signal("Welcome");
export const connectionId = signal("");
export const botData = signal<FlowStep[]>([]);
export const outcomes = signal<OutComes | null>(null);
export const userInput = signal("");
export const userId = signal("0011D000017fwFRQAY");
export const isAgentTransferAvailable = signal(false);
export const disableInput = signal(false);
export const messageBroker = signal(new MessageBroker());
export const isOcrForm = signal<boolean | undefined>(false);
export const ocrProcessingComplete = signal<boolean>(false);

// sales
export const affinityToken = signal<string | null>(null);
export const identifier = signal<string | null>(null);
export const websocket = signal<WebSocket | null>(null);

export const addToConversation = async (payload: ChatBody) => {
  console.log("addToConversation::", payload);
  const messages = [...conversationState.value, payload];
  // sort messages chronologically by timestamp with oldest first
  messages.sort((a, b) => {
    const aTime = a.jsonObject?.timestamp as number;
    const bTime = b.jsonObject?.timestamp as number;
    return aTime - bTime;
  });

  conversationState.value = messages;

  if (!payload.bot) {
    removeHTMLFromConversation();
    if (websocket.value) {
      isBotTyping.value = true;
      websocket.value.send(
        JSON.stringify({
          message: payload.element,
          data: payload.jsonObject?.action || "",
        })
      );
    }
  }
};

export const publishToBackend = async (payload: ChatBody) => {
  console.log("publishToBackend::", payload);
  if (websocket.value) {
    websocket.value.send(
      JSON.stringify({
        message: payload.element,
        data: payload.jsonObject?.action || "",
      })
    );
  }
};

export const removeHTMLFromConversation = () => {
  conversationState.value = [
    ...conversationState.value.filter(
      (e) => typeof e.element === "string" || e.isOpenAI === true
    ),
  ];
};

import {
  affinityToken,
  appId,
  identifier,
  isBotTyping,
  websocket,
} from "../../store/chatStore";

export async function fetchTokenAndIdentifier() {
  if (appId.value) {
    isBotTyping.value = true;
    const data = await fetch("/chat", {
      method: "POST",
      body: JSON.stringify({
        appId: appId.value,
      }),
      headers: {
        "x-trueserve-api-key": "trueserve2024!",
      },
    });
    const result = await data.json();
    affinityToken.value = result.affinityToken;
    identifier.value = result.identifier;
  }
}

export function establishWebsocket() {
  if (affinityToken.value && identifier.value && !websocket.value) {
    console.log("vite process env sales-service", process.env.NODE_ENV);

    

    const currentDomain = window.location.hostname;
    console.log("currentDomain: ", currentDomain);
    let domain = "dev.trueserve.deloitte.com";
    if (currentDomain !== "localhost") {
      // to construct the ws endpoint, remove the subdomain from the current domain and replace with messaging
      // remove subdomain
      const domainParts = currentDomain.split(".");
      domainParts.shift();
      domain = domainParts.join(".");
    }
    console.log("domain: ", domain);
    // construct the ws endpoint
    // TODO make this dynamic in some form
    const wsUrl = `wss://messaging.${domain}/v1/?affinityToken=${affinityToken.value}&identifier=${identifier.value}`;
    // const wsUrl = `wss://messaging.dev.trueserve.deloitte.com/v1/?affinityToken=${affinityToken.value}&identifier=${identifier.value}`;
    console.log("wsURLlllllllllllll: ", wsUrl);
    // Create a new WebSocket instance
    const ws = new WebSocket(wsUrl);
    // Event listeners for WebSocket events
    ws.onopen = () => {
      console.log("WebSocket connection established.");
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed.");
    };
    websocket.value = ws;
  }
}
